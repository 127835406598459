import sendAnalyticsEvent from './sendAnalyticsEvent';
import MHelper from "./MHelper";
import setCookie from './setCookie';
import delayedEvents from './delayedEvents';

const cookieLifetime = 0.013889; // время жизни куки в днях

export default function interstitialObserver() {
  window.addEventListener('hashchange',({ target }) =>{
    if (target.location.hash === '#google_vignette') {
      sendAnalyticsEvent('INTERSTITIAL_AD_SHOW', { label: MHelper.orientation });

      setCookie("shownInterstitial", 1, cookieLifetime);
      delayedEvents().send();
    }
  });
}
