// Экспериментальная обертка вокруг ADX. Добавляет теги для задания минимальной
// цены показа и отслеживает по событиям успешность продажи
function adxCustom() {
  // Куда отправляем отчет по матчрейту
  const statsUrl = '/stats/events';
  // Связака divId => slot
  var adxDisplaySlots = {};

  /// подготовить рекламный блок
  function prepare_slot(divId, initSizes, adunitPath, placeId = null, floor = null){
    var googletag = window.googletag = window.googletag || {cmd: []};
    floor = floor || 0.0;
    placeId = placeId || 'unknown';
    googletag.cmd.push(function() {
      var clientWidth = document.getElementById(divId).clientWidth;
      var sizes = initSizes.filter(function(size) {
        return (!(size instanceof Array) || (size[0] <= clientWidth));
      });

      if(adxDisplaySlots[divId]){
        googletag.destroySlots([adxDisplaySlots[divId]]);
        delete adxDisplaySlots[divId];
      }

      var slot = googletag.defineSlot(adunitPath, sizes, divId);
      if(slot) {adxDisplaySlots[divId] = slot;}

      slot.addService(googletag.pubads());

      if (floor >= 0.01) {
        slot.setTargeting('floor', (floor.toFixed(2)));
      }

      googletag.pubads().addEventListener('slotResponseReceived', adTracer(slot, placeId, floor))
      googletag.pubads().addEventListener('impressionViewable', adTracer(slot, placeId, floor, 'slot_view'))
      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
    });
  }

  /// подготовить к показу межстраничку
  function init_interstitial(adunitPath, placeId = null, floor = null) {
    var googletag = window.googletag = window.googletag || {cmd: []};
    placeId = placeId || 'interstitial';
    floor = floor || 0.0;
    googletag.cmd.push(function() {
      var slot = googletag.defineOutOfPageSlot(
        adunitPath,
        googletag.enums.OutOfPageFormat.INTERSTITIAL
      );

      slot.addService(googletag.pubads());

      if (floor >= 0.01) {
        slot.setTargeting('floor', floor.toFixed(2));
      }

      googletag.pubads().addEventListener('slotResponseReceived', adTracer(slot, placeId, floor))
      googletag.pubads().addEventListener('impressionViewable', adTracer(slot, placeId, floor, 'slot_view'))
      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
      googletag.display(slot);
    });
  }

  /// подготовить вторую межстраничку
  function init_double(adunitPath, placeId = null, floor = null) {
    var googletag = window.googletag = window.googletag || {cmd: []};
    placeId = placeId || 'double';
    floor = floor || 0.0;
    googletag.cmd.push(function() {
      var slot = googletag.defineOutOfPageSlot(
        adunitPath,
        googletag.enums.OutOfPageFormat.GAME_MANUAL_INTERSTITIAL
      );
      if (floor >= 0.01) {
        slot.setTargeting('floor', floor.toFixed(2));
      }
      slot.addService(window.googletag.pubads());

      googletag.pubads().addEventListener('gameManualInterstitialSlotReady', function(event) {
        if(event.slot !== slot) return;
        event.makeGameManualInterstitialVisible();
      });

      googletag.pubads().addEventListener('slotResponseReceived', adTracer(slot, placeId, floor));
      googletag.pubads().addEventListener('impressionViewable', adTracer(slot, placeId, floor, 'slot_view'))
      window.googletag.pubads().enableSingleRequest();
      window.googletag.enableServices();
      window.googletag.display(slot);
    });

  }

  function adTracer(slot, placeId, floor, action_name = 'slot_response') {
    return (event) => {
      if(event.slot !== slot) return;

//      document.dispatchEvent(new Event([placeId, action_name].join('_')));
      var screen_info = {};
      if(window.screen) {
        screen_info['H'] = window.screen.height;
        screen_info['W'] = window.screen.width;
      }
      const sendJson = JSON.stringify({
        locationUrl: window.location.href,
        adxEvent: {action: 'slot_response',
                   data: {placeId: placeId, floor: floor,
                          response: event.slot.getResponseInformation()},
                   screen: screen_info}
      });
      const headers = {type: 'application/json',};
      const blob = new Blob([sendJson], headers);
      navigator.sendBeacon(statsUrl, blob);
    }
  }

  /// показать подготовленный ранее блок (не понимаю почему я его включил в подготовку)
  function display_slot(divId){
    window.googletag = window.googletag || {cmd: []};
    googletag.cmd.push(function() { googletag.display(divId); });
  }


  // {divId: 'div-0', sizes: [[728, 90], [336, 280], [250, 250], 'fluid', [750, 300], [970, 250], [300, 250]],
  //  adunit: '/21728301419/ca-pub-7293384926734597-tag/zistop-app-top020', placeId: 'ztop-app-ATF', floor: 0.0}
  //
  // {adunit: "/21728301419/ca-pub-7293384926734597-tag/zistop-interstitial-top020", placeId: 'ztop-interstitial', floor: 0.0}
  //
  // {adunit: "/21728301419/ca-pub-7293384926734597-tag/zistop-double-top020", placeId: 'double', floor: 0.0}
  function push(data){
    var googletag = window.googletag = window.googletag || {cmd: []};

    if(!data.adunit){
      console.log('adunit required');
    } else if(data.divId) {
      prepare_slot(data.divId, data.sizes, data.adunit, data.placeId, data.floor);
      display_slot(data.divId);
    } else if (data.placeId === 'interstitial') {
      init_interstitial(data.adunit, data.placeId, data.floor);
    } else if (data.placeId === 'double') {
      init_double(data.adunit, data.placeId, data.floor);
    }
  }

  return {prepare_slot: prepare_slot,
          init_double: init_double,
          init_interstitial: init_interstitial,
          display_slot: display_slot,
          push: push};
}

function initAdxCustom() {
  var adxcustom = adxCustom();
  if (Array.isArray(window.adxcustom)) {
    window.adxcustom.forEach((data)=>{adxcustom.push(data)});
  }
  window.adxcustom = adxcustom;
  return adxcustom;
}
export default initAdxCustom;
