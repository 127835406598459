import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme, { breakpoint } from './theme';

import Thumb from './Thumb';
import Info from './Info';
import Loader from './Loader';

import sendAnalyticsEvent from '../utils/sendAnalyticsEvent';
import { thumbsAnalytics } from '../App';

const Component = styled.div`
  min-width: 0;
`;

const Link = styled.a`
  border: 1px solid #D7DFE9;
  padding: 5px 9px;
  color: ${theme.color.text};
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 4px;
  background: white;
  position: relative;
`;

const Picture = styled.div`
  flex: 56px 0 0;
  position: relative;
`;

const Cnt = styled.div`
  padding-left: 10px;
  padding-bottom: 1px;
  overflow: hidden;
`;

const Name = styled.div`
  font-size: 16px;
  margin-bottom: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ThumbStyled = styled(Thumb)`
  border-radius: 4px;

  ${breakpoint.tabletUp} {
    border-radius: 8px;
  }
`;

const Pre = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background: #171f46bb;
  opacity: ${props => props.showLoader ? '1' : '0'};
  visibility: ${props => props.showLoader ? 'visible' : 'hidden'};
  transition: opacity 0.1s linear;
`;

const PreLoader = styled(Loader)`
  display: ${props => props.showLoader ? null : 'none'};
  vertical-align: middle;
  position: relative;
  margin: 0;
  height: 24px;
  width: 24px;
  border-width: 2px;
`;

const Related = ({ loaded, price, score, size, image, title, url, id, position, className }) => {
  const [ showLoader, setShowLoader ] = useState(false);
  const [ timeout ] = useState(1500);
  const info = {
    loaded: loaded,
    price: price,
    score: score,
    size: size
  }

  const elementRef = useRef(null);
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(entries => {
      const { isIntersecting } = entries[0];

      if (isIntersecting) {
        thumbsAnalytics.start(id, 'show video-thumb');
      } else {
        thumbsAnalytics.stop(id);
      }
    }, {threshold: 0.5});

    const element = elementRef.current;
    if (element) {
      observerRef.current.observe(element);
    }

    return () => {
      if (element && observerRef.current) {
        thumbsAnalytics.stop(id);
        observerRef.current.unobserve(element);
      }
    }
  }, [id]);

  const handleClick = () => {
    sendAnalyticsEvent('RELATED_CLICK', {
      position: position + 1,
      id: id,
    });

    thumbsAnalytics.send(id, 'click video-thumb');

    setShowLoader(true);
    setTimeout(() => setShowLoader(false), timeout);
  };

  return (
    <Component className={className} ref={elementRef}>
      <Link href={url} onClick={handleClick} data-timeout={timeout}>
        <Picture>
          <ThumbStyled
            image={image}
            sizeAll={56}
          />
        </Picture>
        <Cnt>
          <Name>{title}</Name>
          <Info {...info} />
        </Cnt>
        <Pre showLoader={showLoader}><PreLoader showLoader={showLoader}/></Pre>
      </Link>
    </Component>
  )
}

export default Related;
