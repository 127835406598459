import React from "react";
import { useStoreon } from 'storeon/react';
import styled from 'styled-components';
import theme from './theme';

const Link = styled.a`
  text-decoration: none;
  display: flex;
  color: ${theme.color.text};
  align-items: center;
  padding: 7px 27px 7px 0;
  margin: 0;
`;

const MenuButton = (props) => {
  const { href, className } = props;
  const { dispatch } = useStoreon('menu');

  return (
    <Link className={className} href={href} onClick={() => dispatch('menu/change')}>{props.children}</Link>
  )
};

export default MenuButton;
