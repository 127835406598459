let globalObject = typeof window === "undefined" ? global : window;
let loc = globalObject.location;

/**
 * Change event
 */
let change = Symbol()

/**
 * Event changes a path
 */
let routerChanged = Symbol()

/**
 * Navigate event
 */
let routerNavigate = Symbol()

/**
 * Router key on store
 */
let routerKey = Symbol('route')

/**
 * Storeon module for URL routing
 * @param {Route[]} routes
 */
function createRouter (routes = [], initPathName) {
  return store => {
    store.on('@init', () => {
      const pathName = (loc && loc.pathname) ? loc.pathname : initPathName;
      store.dispatch(change, parse(pathName, routes))
    })

    store.on(routerChanged, () => {})

    store.on(routerNavigate, (state, path) => {
      if (state[routerKey].path !== path) {
        globalObject.history.pushState(null, null, path)
      }

      store.dispatch(change, parse(path, routes))
      store.dispatch(routerChanged, store.get()[routerKey])
    })

    store.on(change, (state, [path, index, params = []]) => {
      let route = routes[index]
      let newState = {}
      newState[routerKey] = {
        match: false,
        path,
        params
      }

      if (route) {
        newState[routerKey].match = route[1](...params)
      }

      return newState
    })

    if (typeof window !== "undefined") {
      document.documentElement.addEventListener('click', event => {
        let link = event.target.closest('a')
        if (
          !event.defaultPrevented &&
          link != null &&
          link.href.indexOf(loc.origin) === 0 &&
          link.target !== '_blank' &&
          link.dataset.ignoreRouter == null &&
          event.button === 0 &&
          !event.metaKey &&
          !event.ctrlKey &&
          !event.shiftKey &&
          !event.altKey
        ) {
          event.preventDefault();
          const timeout = link.dataset.timeout;
          if (timeout) {
            setTimeout(() => {
              store.dispatch(routerNavigate, link.href.slice(loc.origin.length));
            }, timeout);
          } else store.dispatch(routerNavigate, link.href.slice(loc.origin.length));
        }
      });

      document.addEventListener('changeHref', ({ detail }) => {
        const url = new URL(window.location.origin + detail.href);
        store.dispatch(routerNavigate, url.href.slice(loc.origin.length));
      });
  
      window.addEventListener('popstate', () => {
        if (store.get()[routerKey].path !== loc.pathname) {
          setTimeout(() => {
            store.dispatch(change, parse(loc.pathname, routes))
            store.dispatch(routerChanged, store.get()[routerKey])
          }, 1500); // timeout after pressing the back button
        }
      });
    }
  }
}

/**
 * @private
 * @param {string} path
 * @param {Route[]} routes
 * @return {[string, number, string[]]}
 */
function parse (path, routes) {
  let normalized = path.replace(/(^\/|\/$)/g, '')

  for (let [index, [itemPath]] of routes.entries()) {
    if (typeof itemPath === 'string') {
      let checkPath = itemPath.replace(/(^\/|\/$)/g, '')

      if (checkPath === normalized) {
        return [path, index]
      }

      if (checkPath.includes('*')) {
        let prepareRe = checkPath
          .replace(/[\s!#$()+,.:<=?[\\\]^{|}]/g, '\\$&')
          .replace(/\*/g, '([^/]*)')
        let re = RegExp('^' + prepareRe + '$', 'i')
        let match = normalized.match(re)

        if (match) {
          return [path, index, match.slice(1)]
        }
      }
    }

    if (itemPath instanceof RegExp) {
      let matchRE = normalized.match(itemPath)
      if (matchRE) {
        return [path, index, matchRE.slice(1)]
      }
    }
  }

  return [path]
}

export {
  routerNavigate,
  routerChanged,
  routerKey,
  createRouter
}
