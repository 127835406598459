import React, { useEffect, useRef, useState, Fragment } from 'react';
import ajaxGet from '../utils/ajaxGet';
import { useStoreon } from "storeon/react";
import styled from 'styled-components';
import Loader from './Loader';
import PageLoader from './PageLoader';
import { breakpoint } from './theme';
import debounce from '../utils/debounce';
import setMeta from '../utils/setMeta';
import getJsonPath from '../utils/getJsonPath';
import checkHeight from '../utils/checkHeight';
import handleScroll from '../utils/handleScroll';

import Adv from './Adv';
import CatHead from './CatHead';

import Thumb from "./Thumb";
import Info from './Info';

import sendAnalyticsEvent from '../utils/sendAnalyticsEvent';

const Category = styled.div`
  max-width: 1024px;
  margin: 0 auto 45px;
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 9px 4px 5px;
`;

const Item = styled.a`
  flex: 33.333% 0 0;
  width: 33.333%;
  padding: 6px;
  position: relative;
  text-decoration: none;
  color: inherit;

  &:nth-child(7),
  &:nth-child(8),
  &:nth-child(9),
  &:nth-child(10),
  &:nth-child(11),
  &:nth-child(12) {
    display: none;
  }

  ${breakpoint.mobileBigUp} {
    flex-basis: 25%;
    width: 25%;

    &:nth-child(7),
    &:nth-child(8) {
      display: block;
    }
  }

  ${breakpoint.tabletUp} {
    flex-basis: 20%;
    width: 20%;
    padding: 6px;

    &:nth-child(9),
    &:nth-child(10) {
      display: block;
    }
  }

  ${breakpoint.desktopUp} {
    flex-basis: 16.66%;
    width: 16.66%;
    padding: 12px;

    &:nth-child(11),
    &:nth-child(12) {
      display: block;
    }
  }
`;

const ThumbStyled = styled(Thumb)`
  border-radius: 20px;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin: 7px 0 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PlaylistLoader = styled(Loader)`
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  width: 35px;
  height: 35px;
  margin-bottom: -40px;
`;

const PageCategory = ({ slug }) => {
  const { dispatch, categoryPage } = useStoreon('categoryPage');
  const { ads } = useStoreon('ads');

  const count = useRef(categoryPage ? categoryPage.items.length : 0);
  const isFetching = useRef(false);
  const [showLoader, setShowLoader] = useState(false);
  const scroller = useRef(null);
  const currentSlug = useRef(categoryPage ? categoryPage.slug : '');

  useEffect(() => {
    window.addEventListener('scroll', debouncedScrollListener);
    return () => {
      window.removeEventListener('scroll', debouncedScrollListener);
    }
  }, []);

  useEffect(() => {
    if (!slug || (categoryPage && categoryPage.slug === slug)) {
      setMeta(categoryPage.pageTitle, categoryPage.pageDescription, categoryPage.keywords);
      checkHeight(loadingElements);
    } else {
      ajaxGet(getJsonPath())
        .then(data => {
          count.current = data.categoryPage.items.length;
          dispatch('categoryPage/success', data.categoryPage);
          setMeta(data.categoryPage.pageTitle, data.categoryPage.pageDescription, data.categoryPage.keywords);
          currentSlug.current = data.categoryPage.slug;
          setTimeout(() => checkHeight(loadingElements), 0);
        })
        .catch(error => {
          dispatch('categoryPage/fail');
        });
    }
  }, [slug]);

  const loadingElements = () => {
    isFetching.current = true;
    setShowLoader(true);

    ajaxGet(`${'/data/thumbs/categories/' + currentSlug.current}?offset=${count.current}&limit=4`)
      .then(data => {
        isFetching.current = false;
        setShowLoader(false);
        count.current = count.current + data.items.length;
        dispatch('categoryPage/add', data.items);
      })
      .catch(error => {
        dispatch('categoryPage/fail');
      })
  };

  const hs = () => handleScroll(scroller.current, isFetching.current, loadingElements);
  const debouncedScrollListener = debounce(hs, 150);

  const handleClick = itemId => {
    sendAnalyticsEvent('APP_CLICK', { id: itemId });
  }

  return (
    <>
      {categoryPage && <Category ref={scroller}>
        {categoryPage.items.map((collection, key) => {
          return (<Fragment key={collection.id}>
            { (key === 1 && ads.slots['ztop-preland-ATF']) && <Adv slot={ads.slots['ztop-preland-ATF']} />}
            { (key === 3 && ads.slots['ztop-preland-BTF']) && <Adv slot={ads.slots['ztop-preland-BTF']} />}
            <CatHead name={collection.title} icon={collection.icon} url={collection.url} />
            <List>
            {collection.items.map(item => {
                return (<Item
                  key={item.id}
                  href={item.url}
                  onClick={() => handleClick(item.id)}>

                  <ThumbStyled
                    image={item.image}
                    sizeMobileSmall={145}
                    sizeMobileBig={177}
                    sizeTablet={191}
                  />
                  <Title>{item.title}</Title>
                  <Info score={item.score} loaded={item.loaded} fontSize={10} />
                </Item>)
              })}
          </List></Fragment>)
        })}
        {showLoader && <PlaylistLoader />}
      </Category>}
      {!categoryPage && <PageLoader />}
    </>
  )
}

export default PageCategory;
