const appPage = init => store => {
  store.on('@init', () => ({ appPage: init}));
  store.on('appPage/success', (state, data) => ({ appPage: data}));
  store.on('appPage/add', (state, data) => { 
    const newAppPage = {...state.appPage, related: state.appPage.related.concat(data)};
    return({ appPage: newAppPage});
  });
  store.on('appPage/cleen', () => ({ appPage: false }));
};

export default appPage;
