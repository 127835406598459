import React, { useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import ajaxGet from "../utils/ajaxGet";
import { useStoreon } from "storeon/react";
import theme from "./theme";
import Loader from './Loader';
import setMeta from '../utils/setMeta';
import getJsonPath from "../utils/getJsonPath";

const Page = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 10px;
`;

const Styles = createGlobalStyle`
  .text {
    h1, h2, h3 {
      line-height: 1.2;
      margin: 1em 0 0.68px;
      font-weight: 500;

      &:first-child {
        margin-top: 0;
      }
    }
    
    h1 { font-size: 2em; }
    h2 { font-size: 1.5em; }
    h3 { font-size: 1.2em; }
    p { margin: 1em 0; }
    a { color: ${theme.color.bright}; }
    b, strong { font-weight: bold; }
    em { font-style: italic; }
  }
`;

const PageArticle = ({ slug }) => {
  const { dispatch, articlePage } = useStoreon('articlePage');

  useEffect(() => {
    if (articlePage && articlePage.page_path === slug) {
      setMeta(articlePage.pageTitle, articlePage.pageDescription, articlePage.keywords);
    } else {
      ajaxGet(getJsonPath())
        .then(data => {
          window.scrollTo(0, 0);
          console.log(data);
          dispatch('articlePage/success', data.articlePage);
          setMeta(data.articlePage.pageTitle, data.articlePage.pageDescription, data.articlePage.keywords);
        })
        .catch(error => {
          dispatch('landingPage/fail');
        });
    }
  }, [slug]);

  return (
    <>
      <Styles />
      {articlePage && <Page className="text" dangerouslySetInnerHTML={{__html: articlePage.text}} />}
      {!articlePage && <Loader />}
    </>
  )
};

export default PageArticle;
