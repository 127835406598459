const handleScroll = (el, isFetching, cb) => {
  if (el) {
    const position = window.innerHeight + window.scrollY;
    let playlistHeight =
    el.clientHeight +
    el.offsetTop +
    el.offsetParent.offsetTop;
  
    if (playlistHeight < position && !isFetching) {
      cb();
    }
  }
};

export default handleScroll;
