const categoryPage = init => store => {
  store.on('@init', () => ({ categoryPage: init}));
  store.on('categoryPage/success', (state, data) => ({ categoryPage: data}));
  store.on('categoryPage/add', (state, data) => { 
    const newCategoryPage = {...state.categoryPage, items: state.categoryPage.items.concat(data)};
    console.log(newCategoryPage);
    return({ categoryPage: newCategoryPage});
  });
  store.on('categoryPage/cleen', () => ({ categoryPage: false }));
};

export default categoryPage;
