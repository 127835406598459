import React, { useRef, useEffect, forwardRef, useState } from 'react';
import styled, { css } from 'styled-components';
import ViewerImage from './ViewerImage';
import MHelper from '../utils/MHelper';
import UseIcon from './UseIcon';
import getImage from '../utils/getImage';

import sendAnalyticsEvent from '../utils/sendAnalyticsEvent';

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
  transition: visibility 0s linear ${({ visible }) => visible ? '0s' : '0.2s'};
`;

const Inner = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #171f46cc;
  opacity: ${({ visible }) => visible ? '1' : '0'};
  transition: opacity 0.2s linear 0s;
`;

const Close = styled.button`
  background: rgb(71,	76,	105);
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0%;
  border: 0;
  cursor: pointer;
  z-index: 1;
`;

const Scroller = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  will-change: transform;
  transition: transform 0.2s linear;
  width: 100%;
`;

const ButtonsStyles = css`
  position: fixed;
  top: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-top: -30px;
  background: transparent;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.1s linear;
  user-select: none;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const Next = styled.button`
  ${ButtonsStyles};
  right: 10px;
  padding: 6px 13px 6px 17px;
`;

const Prev = styled.button`
  ${ButtonsStyles};
  left: 10px;
  padding: 6px 17px 6px 13px;
`;

const Viewer = forwardRef(({index, pictures, visible, handleClose, setIndex, slug, showPictures}, ref) => {
  let moving = null;
  const scrollerRef = useRef(null);
  const firstMove = useRef(null);

  useEffect(() => {
    if (scrollerRef.current) {
      scrollerRef.current.style.transform = `translate3d(${-index * MHelper.browserWidth}px, 0px, 0px)`;
    }
  }, [index]);

  const innerRef = useRef(null);

  let startPosCurTop = null;
  let startPosCurLeft = null;
  let direction = null;

  let changedLeft = null;
  let changedTop = null;

  const close = () => {
    handleClose();
    firstMove.current = false;
  }

  const pickup = event => {
    moving = scrollerRef.current;
    startPosCurTop = event.changedTouches[0].clientY;
    startPosCurLeft = event.changedTouches[0].clientX;
    moving.style.transition = 'none';
    moving.style.transform = `translate3d(${-index * MHelper.browserWidth}px, 0px, 0px)`;
  }

  const move = event => {
    if (moving) {
      const movePosCurLeft = event.changedTouches[0].clientX;
      const movePosCurTop = event.changedTouches[0].clientY;
      changedLeft = movePosCurLeft - startPosCurLeft;
      changedTop = movePosCurTop - startPosCurTop;
      
      if (Math.abs(changedLeft) <= Math.abs(changedTop) && !direction) {
        direction = 'Y';
      }

      if (Math.abs(changedLeft) > Math.abs(changedTop) && !direction) {
        direction = 'X';
      }

      if (direction === 'Y') {
        moving.style.transform = `translate3d(${-(index) * MHelper.browserWidth}px, ${changedTop}px, 0px)`;
        innerRef.current.style.opacity = (200 - Math.abs(changedTop)) / 200;
      }

      if (direction === 'X') {
        moving.style.transform = `translate3d(${changedLeft - index * MHelper.browserWidth}px, 0px, 0px)`;

        if (!firstMove.current) {
          firstMove.current = true;
          sendAnalyticsEvent('SCREENS_SCROLL', { id: slug });
        }
      }
    }
  }

  const drop = () => {
    if (moving) {
      moving.style.transition = '';

      if (Math.abs(changedTop) >= 200 && direction === 'Y') {
        close();

        if (changedTop > 0) {
          moving.style.transform = `translate3d(${-index * MHelper.browserWidth}px, ${MHelper.browserHeight}px, 0px)`;
        } else {
          moving.style.transform = `translate3d(${-index * MHelper.browserWidth}px, ${-MHelper.browserHeight}px, 0px)`;
        }

        setTimeout(() => {
          moving.style.transform = `translate3d(${-index * MHelper.browserWidth}px, 0px, 0px)`;
        }, 250);

      } else if (Math.abs(changedTop) < 200 && direction === 'Y') {
        moving.style.transform = `translate3d(${-index * MHelper.browserWidth}px, 0px, 0px)`;
      }

      if (Math.abs(changedLeft) >= 80 && direction === 'X') {

        if(changedLeft < 0 ) {
          makeNext();
        } else {
          makePrev();
        }

      } else if (Math.abs(changedLeft) < 80 && direction === 'X') {
        moving.style.transform = `translate3d(${-index * MHelper.browserWidth}px, 0px, 0px)`
      }

      innerRef.current.style.opacity = '';

      startPosCurTop = null;
      startPosCurLeft = null;
      direction = null;

      changedLeft = null;
      changedTop = null;
    }
  }

  const makePrev = () => {
    if (index-1 >= 0) {
      setIndex(index - 1);
      if (moving)
        moving.style.transform = `translate3d(${-(index-1) * MHelper.browserWidth}px, 0px, 0px)`
    } else {
      moving.style.transform = `translate3d(${-index * MHelper.browserWidth}px, 0px, 0px)`
    }
  };

  const makeNext = () => {
    if (index+1 < pictures.length) {
      setIndex(index + 1);
      if (moving)
        moving.style.transform = `translate3d(${-(index + 1) * MHelper.browserWidth}px, 0px, 0px)`
    } else {
      moving.style.transform = `translate3d(${-index * MHelper.browserWidth}px, 0px, 0px)`
    }
  };

  return (
    <Container ref={ref} visible={visible}>
      <Inner ref={innerRef} visible={visible}>
        <Close onClick={close}><UseIcon name="IconClose" /></Close>
      </Inner>

      <Scroller
        ref={scrollerRef}
        onTouchStart={pickup}
        onTouchEnd={drop}
        // onTouchCancel={}
        onTouchMove={move}
      >
        {pictures.map((src, key) => {
          return (
            <ViewerImage
              key={slug + key}
              src={getImage(src, '1024x1024')}
              srcSet={`${getImage(src, '1024x1024', 1)} 1x, ${getImage(src, '1024x1024', 2)} 2x`}
              visible={visible}
              show={showPictures}
            />
          )
        })}
      </Scroller>
      {!MHelper.hasTouchScreen && <>
        { index !== (pictures.length - 1) && <Next onClick={makeNext}><UseIcon name="IconNext" /></Next>}
        { index !== 0 && <Prev onClick={makePrev}><UseIcon name="IconPrev" /></Prev>}
      </>}
    </Container>
  )
});

export default Viewer;
