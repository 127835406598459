import React from 'react';

import Page404 from './components/Page404';
import PageHome from './components/PageHome';
import PageCollection from './components/PageCollection';
import PageCategory from './components/PageCategory';
import PagePreland from './components/PagePreland';
import PagePrelandZ1 from './components/PagePrelandZ1';
import PagePrelandZ2Old from './components/PagePrelandZ2Old';
import PageApp from './components/PageApp';
import PageDownload from './components/PageDownload';
import PageArticle from './components/PageArticle';
import PageContact from './components/PageContact';
import PageSearch from './components/PageSearch';

import { useStoreon } from 'storeon/react';
import { routerKey } from './utils/storeonRouter';


function Router() {
  let { [routerKey]: route } = useStoreon(routerKey);
  let { page, slug } = route.match;

  if (route.match === false) return <Page404 />;

  switch (page) {
    case 'home':
      return <PageHome />

    case 'contact':
      return <PageContact slug={slug} />

    case 'collection':
      return <PageCollection slug={slug} />

    case 'category':
      return <PageCategory slug={slug} />

    case 'app':
      return <PageApp slug={slug} />

    case 'download':
      return <PageDownload slug={slug} />

    case 'preland':
      return <PagePreland slug={slug} />

    case 'preland-z1':
      return <PagePrelandZ1 slug={slug} />

    case 'preland-z2':
      return <PagePrelandZ2Old slug={slug} />

    case 'article':
      return <PageArticle slug={slug} />
    
    case 'search':
      return <PageSearch slug={slug}  />

    default:
      return <Page404 />
  }
}

export default Router;
