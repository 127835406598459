import ReactOnRails from 'react-on-rails';
import Root from '../bundles/ZisTop/Root';
import * as Sentry from '@sentry/react';
//import { BrowserTracing } from "@sentry/tracing";

import initAdxCustom from 'adx_slot_constructor';

Sentry.init({
  dsn: "https://9eb7297329b147c8a4f99550be067808@traces.mynewcdn.com/5",
//integrations: [new BrowserTracing()],
//  tracesSampleRate: 0.01
});

if (typeof window !== 'undefined') {initAdxCustom();}

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({ Root });
