import React, { useEffect, useRef } from 'react';

import { thumbsAnalytics } from '../App';

const PrelandItem = ({ id, key, className, href, onClick, children }) => {
  const elementRef = useRef(null);
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(entries => {
      const { isIntersecting } = entries[0];

      if (isIntersecting) {
        thumbsAnalytics.start(id, 'show video-thumb');
      } else {
        thumbsAnalytics.stop(id);
      }
    }, {threshold: 0.5});

    const element = elementRef.current;
    if (element) {
      observerRef.current.observe(element);
    }

    return () => {
      if (element && observerRef.current) {
        thumbsAnalytics.stop(id);
        observerRef.current.unobserve(element);
      }
    }
  }, [id]);

  return (
    <a ref={elementRef}
      key={key}
      className={className}
      href={href}
      onClick={onClick}>
      {children}
    </a>
  )
};

export default PrelandItem;
