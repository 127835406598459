import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import getImage from '../utils/getImage';

const Slide = styled.div`
  user-select: none;
`;

const Thumb = styled.img`
  height: 160px;
  vertical-align: bottom;
  padding: 0 4px;
`;

const SliderItem = ({ item, show, showAll, onClick, onLoad }) => {
  const imgRef = useRef(null);
  
  useEffect(() => {
    if (imgRef.current.complete && (show || showAll)) onLoad(imgRef.current.offsetLeft + imgRef.current.offsetWidth);
  }, [])

  const handleLoad = () => {
    onLoad(imgRef.current.offsetLeft + imgRef.current.offsetWidth);
  };

  return (
    <Slide>
      <Thumb
        ref={imgRef}
        src={(show || showAll) ? getImage(item, '500x160') : null}
        srcSet={(show || showAll) ? `${getImage(item, '500x160', 1)} 1x, ${getImage(item, '500x160', 2)} 2x` : null}
        alt=""
        onClick={onClick} onLoad={handleLoad}
      />
    </Slide>
  )
};

export default SliderItem;
