import React, { useEffect, useRef, useState } from 'react';
import ajaxGet from '../utils/ajaxGet';
import { useStoreon } from "storeon/react";
import styled from 'styled-components';
import Loader from './Loader';
import PageLoader from './PageLoader';
import { breakpoint } from './theme';
import debounce from '../utils/debounce';
import setMeta from '../utils/setMeta';
import handleScroll from '../utils/handleScroll';
import MHelper from '../utils/MHelper';

import PrelandItem from './PrelandItem';
import TopApp from './TopApp';
import Adv from './Adv';
import Thumb from "./Thumb";
import Info from './Info';

import sendAnalyticsEvent from '../utils/sendAnalyticsEvent';
import { thumbsAnalytics } from '../App';

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 9px 4px 5px;

  max-width: 1024px;
  margin: 0 auto;
`;

const Item = styled(PrelandItem)`
  flex: 25% 0 0;
  width: 25%;
  padding: 6px;
  position: relative;
  text-decoration: none;
  color: inherit;

  ${breakpoint.tabletUp} {
    flex-basis: 20%;
    width: 20%;
    padding: 6px;
  }

  ${breakpoint.desktopUp} {
    flex-basis: 16.66%;
    width: 16.66%;
    padding: 12px;
  }
`;

const ItemFirst = styled(Item)`
  &:nth-child(9),
  &:nth-child(10),
  &:nth-child(11),
  &:nth-child(12) {
    display: none;
  }

  ${breakpoint.tabletUp} {
    &:nth-child(9),
    &:nth-child(10) {
      display: block;
    }
  }

  ${breakpoint.desktopUp} {
    &:nth-child(11),
    &:nth-child(12) {
      display: block;
    }
  }
`;

const ItemSecond = styled(Item)`
  &:nth-child(13),
  &:nth-child(14),
  &:nth-child(15),
  &:nth-child(16),
  &:nth-child(17),
  &:nth-child(18),
  &:nth-child(19),
  &:nth-child(20),
  &:nth-child(21),
  &:nth-child(22) {
    display: none;
  }


  ${breakpoint.tabletUp} {
    &:nth-child(13),
    &:nth-child(14),
    &:nth-child(15),
    &:nth-child(16),
    &:nth-child(17) {
      display: block;
    }

    &:nth-child(1),
    &:nth-child(2) {
      display: none;
    }
  }

  ${breakpoint.desktopUp} {
    &:nth-child(18),
    &:nth-child(19),
    &:nth-child(20),
    &:nth-child(21),
    &:nth-child(22) {
      display: block;
    }

    &:nth-child(3),
    &:nth-child(4) {
      display: none;
    }
  }
`;

const ItemThird = styled(Item)`
  ${breakpoint.tabletUp} {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      display: none;
    }
  }

  ${breakpoint.desktopUp} {
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10) {
      display: none;
    }
  }
`;

const ThumbStyled = styled(Thumb)`
  border-radius: 4px;

  ${breakpoint.tabletUp} {
    border-radius: 20px;
  }

  ${breakpoint.desktopUp} {
    border-radius: 30px;
  }
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin: 7px 0 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PlaylistLoader = styled(Loader)`
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  width: 35px;
  height: 35px;
  margin-bottom: 5px;
`;

const PagePrelandZ1 = () => {
  const { dispatch, landerPage, appPage, ads } = useStoreon('landerPage', 'appPage', 'ads');
  const pageData = landerPage ? landerPage : appPage;
  const actionPrefix = landerPage ? 'landerPage' : 'appPage';

  const count = useRef(pageData ? pageData.related.length : 0);
  const isFetching = useRef(false);
  const [showLoader, setShowLoader] = useState(false);
  const scroller = useRef(null);

  const isFiveEls = () => {
    return !MHelper.ifSSR && MHelper.browserWidth >= 768 && MHelper.browserWidth < 1024;
  };

  const limit = useRef(isFiveEls() ? 10 : 12);

  useEffect(() => {
    setMeta(pageData.pageTitle, pageData.pageDescription, pageData.keywords);
    
    if (window.innerHeight >= document.querySelector('.root').offsetHeight) {
      if(isFiveEls()) loadingElements(13);
      else loadingElements(12)
    } else if(isFiveEls()) loadingElements(3)

    window.addEventListener('scroll', debouncedScrollListener);
    window.addEventListener('orientationchange', orientationChange);
    return () => {
      window.removeEventListener('scroll', debouncedScrollListener);
      window.removeEventListener('orientationchange', orientationChange);
    }
  }, []);

  const orientationChange = () => setTimeout(() => {
    limit.current = isFiveEls() ? 10 : 12;
    if (isFiveEls() && count.current%5 !== 0)
      loadingElements(5 - count.current%5);

    if (!isFiveEls() && count.current%6 !== 0)
      loadingElements(6 - count.current%6);
  }, 250);

  const loadingElements = (lm) => {
    isFetching.current = true;
    setShowLoader(true);

    ajaxGet(`/data/thumbs/apps/${pageData.id}?offset=${count.current}&limit=${lm || limit.current}`)
      .then(data => {
        isFetching.current = false;
        setShowLoader(false);
        count.current = count.current + data.related.length;
        dispatch(`${actionPrefix}/add`, data.related);
      })
      .catch(error => {
        dispatch(`${actionPrefix}/fail`);
      });
  };

  const hs = () => handleScroll(scroller.current, isFetching.current, loadingElements);
  const debouncedScrollListener = debounce(hs, 150);

  const handleClick = (id) => {
    sendAnalyticsEvent('PRELAND_CLICKED');
    thumbsAnalytics.send(id, 'click video-thumb');
  }

  return (
    <>
      {pageData && <>
      <TopApp {...pageData} onClick={() => handleClick(pageData.id)} />
      <List>
        {pageData.related.slice(0,12).map((item, key) => {
          return (
            <ItemFirst
              key={item.id}
              id={item.id}
              href={item.url}
              onClick={() => handleClick(item.id)}>
              <ThumbStyled
                image={item.image}
                sizeMobileSmall={105}
                sizeMobileBig={177}
                sizeTablet={191}
                dataSrc={key>=4}
                disableSmallImageLazy={key<4}
              />
              <Title>{item.title}</Title>
              <Info score={item.score} loaded={item.loaded} fontSize={10} />
            </ItemFirst>
          )
        })}
      </List>

      {ads.slots['ztop-preland-ATF'] && <Adv slot={ads.slots['ztop-preland-ATF']} />}

      {pageData.related.length > 8 && <List ref={scroller}>
        {pageData.related.slice(8,30).map(item => {
          return (
            <ItemSecond
              key={item.id}
              id={item.id}
              href={item.url}
              onClick={() => handleClick(item.id)}>
              <ThumbStyled
                image={item.image}
                sizeMobileSmall={105}
                sizeMobileBig={177}
                sizeTablet={191}
              />
              <Title>{item.title}</Title>
              <Info score={item.score} loaded={item.loaded} fontSize={10} />
            </ItemSecond>
          )
        })}
      </List>}

      {(pageData.related.length > 20 && ads.slots['ztop-preland-BTF']) && <Adv slot={ads.slots['ztop-preland-BTF']} />}

      {pageData.related.length > 20 && <List ref={scroller}>
        {pageData.related.slice(20).map(item => {
          return (
            <ItemThird
              key={item.id}
              id={item.id}
              href={item.url}
              onClick={() => handleClick(item.id)}>
              <ThumbStyled
                image={item.image}
                sizeMobileSmall={105}
                sizeMobileBig={177}
                sizeTablet={191}
              />
              <Title>{item.title}</Title>
              <Info score={item.score} loaded={item.loaded} fontSize={10} />
            </ItemThird>
          )
        })}
      </List>}
      {showLoader && <PlaylistLoader />}
    </>}
    {!pageData && <PageLoader />}
    </>
  )
}

export default PagePrelandZ1;
