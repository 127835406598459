import React from 'react';
import styled from "styled-components";
import theme, { breakpoint } from "./theme";

const Container = styled.div`
  display: table;
  line-height: 1.5em;
  margin: 7px 0 5px;

  ${breakpoint.tabletUp} {
    margin-top: 0;
  }
`;

const Row = styled.div`
  display: table-row;
`;

const Cell = styled.div`
  display: table-cell;
  padding: 3px 10px 4px;
  width: 50%;

  &:first-child {
    padding-left: 0;
    color: ${theme.color.textGray};
    font-weight: 500;

    ${breakpoint.tabletUp} {
      width: 55%;
    }
  }

  &:last-child {
    padding-right: 0;

    ${breakpoint.tabletUp} {
      width: 45%;
    }
  }
`;

const AdditionalInfo = ({ info }) => {
  return (
    <Container>
      {info.map((item, key) => (<Row key={key}>
        <Cell>{item[0]}:</Cell>
        <Cell>{item[1]}</Cell>
      </Row>))}
    </Container>
    )
  }
;

export default AdditionalInfo;
