const getGoogleIdentifiers = () => {
  let _ga, _gid;

  if (window.ga) {
    window.ga.getAll().forEach(tracker => {
      const gaId = tracker.get("clientId");
      const gidId = tracker.get("_gid");
  
      document.cookie.split(";").forEach(function (el) {
        if (el.includes(gaId)) _ga = el.split("=")[1];
        if (el.includes(gidId)) _gid = el.split("=")[1];
      });
    });
  }

  return {ga: _ga, gid: _gid};
}

export default getGoogleIdentifiers;
