import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { breakpoint } from './theme';
import getImage from '../utils/getImage';

import Thumb from './Thumb';
import Info from './Info';

import { thumbsAnalytics } from '../App';

const Container = styled.a`
  background: linear-gradient(92.97deg, #0E1954 0.23%, #0C1B67 11.75%, #0C1547 45.62%, #0A1652 87.15%, #091036 100%);
  padding: 9px 10px 11px;
  display: block;
  text-decoration: none;

  ${breakpoint.tabletUp} {
    padding: 12px 0 12px;
    margin-bottom: -5px;
  }
`;

const Inner = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  display: flex;

  ${breakpoint.tabletUp} {
    padding: 0 16px;
  }
`;

const ColThumb = styled.div`
  width: 68px;

  ${breakpoint.tabletUp} {
    width: 96px;
  }
`;

const ColLeft = styled.div`
  flex: 68px 0 0;

  ${breakpoint.tabletUp} {
    flex-basis: auto;
    display: flex;
    align-items: center;
  }
`;

const StyledThumb = styled(Thumb)`
  border-radius: 8px;
`;

const ColTop = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;

  ${breakpoint.tabletUp} {
    display: none;
  }
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  opacity: 0.8;
  color: white;
  text-transform: uppercase;

  padding-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (min-width: 450px) {
    max-width: 60%;
  }
`;

const Gallery = styled.div`
  display: flex;
  justify-content: space-beetween;
  padding-left: 19px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
`;

const Item = styled.div`
  padding: 0 2px;

  ${breakpoint.tabletUp} {
    padding: 0 3px;
  }
`;

const Image = styled.img`
  height: 68px;
  border-radius: 4px;
  vertical-align: bottom;

  ${breakpoint.tabletUp} {
    height: 96px;
  }
`;

const AppInfo = styled.div`
  display: none;
  margin-left: 24px;

  ${breakpoint.tabletUp} {
    display: block;
  }
`;

const Type = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: white;
  margin-bottom: 7px;
  opacity: 0.7;

  max-width: 210px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${breakpoint.desktopUp} {
    max-width: 260px;
  }
`;

const AppTitle = styled.h1`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: white;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin-bottom: 10px;

  max-width: 210px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${breakpoint.desktopUp} {
    max-width: 260px;
  }
`;

const TopApp = ({ id, price, loaded, score, size, title, image, url, pictures, onClick }) => {
  const elementRef = useRef(null);
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(entries => {
      const { isIntersecting } = entries[0];

      if (isIntersecting) {
        thumbsAnalytics.start(id, 'show video-thumb');
      } else {
        thumbsAnalytics.stop(id);
      }
    }, {threshold: 0.5});

    const element = elementRef.current;
    if (element) {
      observerRef.current.observe(element);
    }

    return () => {
      if (element && observerRef.current) {
        thumbsAnalytics.stop(id);
        observerRef.current.unobserve(element);
      }
    }
  }, [id]);

  return (
    <Container href={url} onClick={onClick} ref={elementRef}>
      <ColTop>
        <Title>{title}</Title>
        <Info price={price} loaded={loaded} score={score} />
      </ColTop>
      <Inner>
        <ColLeft>
          <ColThumb>
            <StyledThumb
              image={image}
              sizeMobile={68}
              sizeTablet={96}
              disableLazy={true}
            />
          </ColThumb>
          <AppInfo>
            <AppTitle>{title}</AppTitle>
            <Type>Mizoxat Studio 2018 / Action Shooting Games </Type>
            <Info price={price} loaded={loaded} score={score} size={size} />
          </AppInfo>
        </ColLeft>
        {pictures && <Gallery>
          {pictures.map((picture, key) => (
            <Item key={key}>
              <picture>
                <source srcSet={`${getImage(picture, 122, 1)} 1x`} media="(min-width: 0px) and (max-width: 767px)" />
                <source srcSet={`${getImage(picture, 171, 1)} 1x`} media="(min-width: 768px)" />
                <Image src={getImage(picture, 122)} alt="" />
              </picture>
            </Item>
          ))}
        </Gallery>}
      </Inner>
    </Container>
  )
};

export default TopApp;
