import React, { useRef, useEffect } from 'react';
import { ServerStyleSheet } from 'styled-components';
import { renderToString } from 'react-dom/server';
import { StoreContext } from 'storeon/react';

import { routerChanged } from './utils/storeonRouter';
import getStore from './store/index';

import Analytics from './utils/Analytics';
import analyticsParams from './analyticsParams';
import sendAnalyticsEvent from './utils/sendAnalyticsEvent';
import interstitialObserver from './utils/interstitialObserver';
import debounce from './utils/debounce';

import App from "./App";

const Server = (props, pathname) => {
  console.log('server', pathname);

  const { homePage, collectionPage, categoryPage, appPage, landerPage, articlePage, contactPage, categoriesMenu, adsSlots, cookiePolicy, legalLinks, siteFeatures } = props;
  const store = getStore(pathname, homePage, collectionPage, categoryPage, appPage, landerPage, articlePage, contactPage, categoriesMenu, adsSlots, cookiePolicy, legalLinks, siteFeatures);

  const Html = <React.StrictMode>
      <StoreContext.Provider value={store}>
        <App {...props} />
      </StoreContext.Provider>
    </React.StrictMode>
    ;

  const sheet = new ServerStyleSheet();
  try {
    renderToString(sheet.collectStyles(Html));
    const Styles = sheet.getStyleElement();
    return (
      <>{Styles}{Html}</>
    );
  } catch (error) {
    console.error(error);
  } finally {
    sheet.seal();
  }
};

const Browser = (props) => {
  console.log('browser');
  const scrolledPage = useRef(false);

  const { homePage, collectionPage, categoryPage, appPage, landerPage, articlePage, contactPage, categoriesMenu, adsSlots, cookiePolicy, legalLinks, gtagEventsMap, siteFeatures } = props;
  const store = getStore(false, homePage, collectionPage, categoryPage, appPage, landerPage, articlePage, contactPage, categoriesMenu, adsSlots, cookiePolicy, legalLinks, siteFeatures);

  interstitialObserver();
  const analytics = new Analytics(Object.assign(analyticsParams, { gtagEventsMap }));

  const handleScroll = () => {
    if(!scrolledPage.current) {
      sendAnalyticsEvent('PAGE_SCROLL');
      scrolledPage.current = true;
    }
  };

  const debouncedScrollListener = debounce(handleScroll, 150);

  useEffect(() => {
    window.addEventListener('scroll', debouncedScrollListener);
  }, []);

  store.on(routerChanged, (_, data) => {
    scrolledPage.current = false;
    analytics.nextPage(data.path);
  });

  return (
    <React.StrictMode>
      <StoreContext.Provider value={store}>
        <App {...props} />
      </StoreContext.Provider>
    </React.StrictMode>
  )
};

const Root = (props, railsContext) => {
  console.log(railsContext);
  console.log(props);
  const { serverSide, pathname } = railsContext;

  if (serverSide) {
    return () => Server(props, pathname);
  } else {
    return () => Browser(props, pathname);
  }
};

export default Root;
