import React from 'react';
import styled from 'styled-components';

import UseIcon from "./UseIcon";

const Container = styled.div`
  color: #888da2;
  font-size: ${({ fontSize }) => fontSize ? fontSize : '12'}px;
  display: flex;
  align-items: center;
`;

const InfoText = styled.span`
  margin-left: 3px;
`;

const InfoItem = styled.span`
  margin-right: 10px;
  display: inline-flex;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }
`;

const Info = ({ score, loaded, price, size, type, className, fontSize }) => {
  return (
    <Container className={className} fontSize={fontSize}>
      {score && <InfoItem><UseIcon name="IconStar" color="#888da2" /><InfoText>{score}</InfoText></InfoItem>}
      {loaded && <InfoItem><UseIcon name="IconDl" color="#888da2" /><InfoText>{loaded}</InfoText></InfoItem>}
      {price && <InfoItem>{price}</InfoItem>}
      {size && <InfoItem>{size}</InfoItem>}
      {type && <InfoItem>{type}</InfoItem>}
    </Container>
  )
};

export default Info;
