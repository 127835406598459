import React from 'react';
import styled from 'styled-components';

import GlobalStyle from './components/GlobalStyle';
import Menu from './components/Menu';
import Header from './components/Header';
import Footer from './components/Footer';
import Router from './Router';

import ThumbsAnalytics from './utils/ThumbsAnalytics';
export const thumbsAnalytics = new ThumbsAnalytics();

const Main = styled.main`
  max-width: 1920px;
  margin: 0 auto;
  flex: 1 0 auto;
  width: 100%;
  position: relative;
`;

const App = () => (
  <>
    <GlobalStyle />
    <Header />
    <Menu />
    <Main>
      <Router />
    </Main>
    <Footer />
  </>
);

export default App;
