import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { breakpoint } from "./theme";

import ajaxGet from "../utils/ajaxGet";
import debounce from '../utils/debounce';
import handleScroll from '../utils/handleScroll';
import sendAnalyticsEvent from "../utils/sendAnalyticsEvent";

import PageLoader from './PageLoader';
import Loader from "./Loader";
import Thumb from "./Thumb";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 9px auto 45px;
  max-width: 1024px;
  padding: 0 4px;
  position: relative;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin: 7px 0 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Item = styled.a`
  flex: 33.333% 0 0;
  width: 33.333%;
  padding: 6px;
  position: relative;
  text-decoration: none;
  color: inherit;

  ${breakpoint.mobileBigUp} {
    flex-basis: 25%;
    width: 25%;
  }

  ${breakpoint.tabletUp} {
    flex-basis: 20%;
    width: 20%;
    padding: 6px;
  }

  ${breakpoint.desktopUp} {
    flex-basis: 16.66%;
    width: 16.66%;
    padding: 12px;
  }
`;

const PlaylistLoader = styled(Loader)`
  position: absolute;
  left: 0;
  display: block;
  width: 35px;
  height: 35px;
  bottom: -35px;
  top: auto;
  margin: 0 auto;
  right: 0;
`;

const PageSearch = ({ slug }) => {
  const scroller = useRef(null);
  const count = useRef(0);
  const slugRef = useRef(slug);

  const isFetching = useRef(false);
  const [ showLoader, setShowLoader ] = useState(false);

  const [ searchItems, _setSearchItems ] = useState([]);
  const searchItemsRef = useRef(searchItems);
  const setSearchItems = data => {
    searchItemsRef.current = data;
    _setSearchItems(data);
  };


  const getPath = (limit) => {
    const path = window.location.origin + '/data/thumbs/search?query=' + slugRef.current;
    return `${path}&offset=${count.current}&limit=${limit}`
  }

  useEffect(() => {
    window.addEventListener('scroll', debouncedScrollListener);
    return () => {
      window.removeEventListener('scroll', debouncedScrollListener);
    }
  }, []);

  useEffect(() => {
    slugRef.current = slug;
    count.current = 0;
    setSearchItems([]);

    ajaxGet(getPath(30))
      .then(data => {
        isFetching.current = false;
        setShowLoader(false);
        count.current = count.current + data.items.length;
        setSearchItems(data.items);
      })
      .catch(error => {
        console.log(error);
      });

  }, [slug]);


  const loadingElements = () => {
    isFetching.current = true;
    setShowLoader(true);

    ajaxGet(getPath(12))
      .then(data => {
        isFetching.current = false;
        setShowLoader(false);
        count.current = count.current + data.items.length;
        setSearchItems(searchItemsRef.current.concat(data.items));
      })
      .catch(error => {
        console.log(error);
      })
  };

  const hs = () => handleScroll(scroller.current, isFetching.current, loadingElements);
  const debouncedScrollListener = debounce(hs, 150);

  const handleClick = itemId => {
    sendAnalyticsEvent('APP_CLICK', { id: itemId });
  }

  return (
    <>
      {!!searchItems.length && <Container ref={scroller}>
        {searchItems && searchItems.map((item) => {
          return <Item
            key={item.id}
            href={item.url}
            onClick={() => handleClick(item.id)}>
            <Thumb
              image={item.image}
              sizeMobileSmall={145}
              sizeMobileBig={177}
              sizeTablet={191}
            />
            <Title>{item.title}</Title>
          </Item>
        })}
        {showLoader && <PlaylistLoader />}
      </Container>}
      {!searchItems.length && <PageLoader />}
    </>
  )
};

export default PageSearch;
 