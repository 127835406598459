import React, { Fragment } from 'react';
import styled from 'styled-components';
import { breakpoint } from './theme';
import { useStoreon } from "storeon/react";

import Related from './Related';
import Adv from './Adv';

const SubTitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
  margin: 21px 10px 11px;
`;

const RelatedList = styled.div`
  margin: 10px 10px 45px;

  ${breakpoint.tabletUp} {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 6px 45px;
  }
`;

const RelatedStyled = styled(Related)`
  margin: 4px 0;
  order: 5;

  ${breakpoint.tabletUp} {
    flex: 0 0 50%;
    margin: 3px 0;
    padding: 0 3px;

    order: 5;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(5) {
      order: 1;
    }
  }

  ${breakpoint.desktopUp} {
    flex-basis: 33.333%;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      order: 1;
    }

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(7) {
      order: 3;
    }
  }
`;

const AdvStyled = styled(Adv)`
  padding: 15px 0;

  ${breakpoint.tabletUp} {
    &:nth-child(4) {
      order: 2;
    }

    &:nth-child(6) {
      order: 4;
    }
  }
`;

const RelatedBlock = ({ related, adPosition, adName, slug }) => {
  const { ads } = useStoreon('ads');
  return (
    <>
      <SubTitle>Best Similar Games</SubTitle>
      <RelatedList>
        {related.map((item, key) => (
          <Fragment key={key}>
            <RelatedStyled position={key} {...item} />
            {(adPosition === (key+1) && ads.slots[adName]) && <AdvStyled
              lazy={true}
              slot={ads.slots[adName]}
              minHeight={100}
              key={slug + adPosition}
            />}
          </Fragment>
        ))}
      </RelatedList>
    </>
  )
};

export default RelatedBlock;
