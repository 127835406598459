import React, { useEffect, useState, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import sendAnalyticsEvent from '../utils/sendAnalyticsEvent';

import Slider from './Slider';
import Viewer from './Viewer';

const Gallery = ({ video, pictures, slug }) => {
  const pzRef = useRef(null);
  const [ visible, setVisible ] = useState(false);
  const [ indexViewer, setIndexViewer ] = useState(0);
  const [ moved, setMoved ] = useState(false);
  const [showViewerPictures, setShowViewerPictures] = useState(false);

  useEffect(() => {
    setMoved(false);
    setShowViewerPictures(false);
  }, [slug]);

  const handleVisible = id => {
    setIndexViewer(id);
    disableBodyScroll(pzRef.current);
    setVisible(true);
    setShowViewerPictures(true);
  };

  const handleClose = () => {
    setVisible(false);
    enableBodyScroll(pzRef.current);
  };

  const handleMoved = () => {
    if (!moved) {
      setMoved(true);
      sendAnalyticsEvent('SCREENS_SCROLL', { id: slug });
    }
  }

  return (
    <>
      <Slider
        slug={slug}
        video={video}
        pictures={pictures}
        handleVisible={handleVisible}
      />
      <Viewer
        index={indexViewer}
        pictures={pictures}
        ref={pzRef}
        visible={visible}
        handleClose={handleClose}
        setIndex={setIndexViewer}
        slug={slug}
        handleMoved={handleMoved}
        showPictures={showViewerPictures}
      />
    </>
  )
};

export default Gallery;
