import { createRouter } from '../utils/storeonRouter';

const router = initPathName => (createRouter([
  ['/', () => (
    { page: 'home' }
  )],
  ['/contact/', () => {
    return { page: 'contact'}
  }],
  ['/app/*/', slug => {
    return { page: 'app', slug }
  }],
  ['/cat/*/', slug => {
    return { page: 'category', slug }
  }],
  ['/cat/*/*/', (catSlug, slug) => {
    return { page: 'collection', catSlug, slug }
  }],
  ['/coll/*/', slug => {
    return { page: 'collection', slug }
  }],
  ['/app/*/download/', slug => {
    return { page: 'download', slug }
  }],
  ['/url/*/*/', slug => {
    return { page: 'preland', slug }
  }],
  ['/z1-*/', code => {
    return { page: 'preland-z1', code }
  }],
  ['/z2-*/', code => {
    return { page: 'preland-z2', code }
  }],
  ['/z3-*/', code => {
    return { page: 'preland-z1', code }
  }],
  ['/z4-*/', code => {
    return { page: 'preland-z2', code }
  }],
  ['/z1-*/utm-*/', code => {
    return { page: 'preland-z1', code }
  }],
  ['/z2-*/utm-*/', code => {
    return { page: 'preland-z2', code }
  }],
  ['/z3-*/utm-*/', code => {
    return { page: 'preland-z1', code }
  }],
  ['/z4-*/utm-*/', code => {
    return { page: 'preland-z2', code }
  }],
  ['/about/*/', slug => {
    return { page: 'article', slug }
  }],
  ['/search/*/', slug => {
    return { page: 'search', slug }
  }],
], initPathName));

export default router;
