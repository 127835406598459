import React from "react";
import styled from "styled-components";

import UseIcon from "./UseIcon";

const Head = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px auto 9px;
  padding: 0 10px;
  max-width: 1024px;
`;

const HeadLeft = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  margin-left: 8px;
`;

const More = styled.a`
  font-weight: 500;
  font-size: 14px;
  color: #7E858E;
  color: #6d737b;
  
  text-decoration: none;
`;

const CatHead = ({ title, name, url, icon }) => (<Head>
    <HeadLeft>
      {icon && <UseIcon name={icon} />}
      <Title>{title || name}</Title>
    </HeadLeft>
    {url && <More href={url}>Show all Top</More>}
  </Head>
);

export default CatHead;
