const collectionPage = init => store => {
  store.on('@init', () => ({ collectionPage: init}));
  store.on('collectionPage/success', (state, data) => ({ collectionPage: data}));
  store.on('collectionPage/add', (state, data) => { 
    const newCollectionPage = {...state.collectionPage, items: state.collectionPage.items.concat(data)};
    console.log(newCollectionPage);
    return({ collectionPage: newCollectionPage});
  });
  store.on('collectionPage/cleen', () => ({ collectionPage: false }));
};

export default collectionPage;
