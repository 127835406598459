import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoint } from "./theme";

const ButtonStyles = css`
  background: radial-gradient(85.79% 150.32% at 100% 50%,#FFA21F 0%,#FF6026 100%);;
  border: 0;
  color: white;
  border-radius: 4px;
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 500;
  opacity: 0.9;
  cursor: pointer;
  transition: opacity 0.1s linear;
  outline: none;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  height: 40px;
  font-family: inherit;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  
  :hover {
    opacity: 0.8;
  }

  ${breakpoint.tabletUp} {
    height: 48px;
  }
`;

const TagButton = styled.button`
  ${ButtonStyles};
`;

const TagLink = styled.a`
  ${ButtonStyles};
`;

const Button = ({ children, className, href, external, onClick }) => {
  const Tag = href ? TagLink : TagButton;

  return (
    <Tag
      href={href}
      className={className}
      target={external ? '_blank' : undefined}
      rel={external ? 'noopener' : undefined}
      onClick={onClick}
    >{children}</Tag>
  )
};

export default Button;
