import React from "react";
import styled from 'styled-components';
import Button from "./Button";

const PageTag = styled.div`
  text-align: center;
  min-height: 200px;
  padding-top: 100px;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 150px;
  line-height: 0.8;
`;

const Text = styled.div`
  font-size: 36px;
  font-weight: 500;
  line-height: 1;
`;

const Small = styled.div`
  max-width: 300px;
  margin: 20px auto;
`;

const Page404 = () => {
  return (
    <PageTag>
      <Title>404</Title>
      <Text>Page not found</Text>
      <Small>The page you're looking for has either been moved or removed from the site.</Small>
      <Button href="/">Go Home</Button>
    </PageTag>
  )
};

export default Page404;
