import { createGlobalStyle } from 'styled-components';
import theme from './theme';

import RubikRegularLatin from '../libs/Rubik/RubikRegularLatin.woff2';
import RubikMediumLatin from '../libs/Rubik/RubikMediumLatin.woff2';

const GlobalStyle = createGlobalStyle`
  // RESET
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
    font-family: sans-serif;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  } 
  // END RESET

  // FONTS

  /* latin */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Rubik'), local('Rubik-Regular'), url(${RubikRegularLatin}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* latin */
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Rubik Medium'), local('Rubik-Medium'), url(${RubikMediumLatin}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  // END FONTS

  // BOX SIZING
  html {
    box-sizing: border-box;
    -webkit-text-size-adjust: none;
    height: 100%;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  // INITIAL STYLES
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    line-height: 1.25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${theme.color.text};
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  a {
    color: ${theme.color.common};
  }
  
  .root {
    background-color: ${theme.color.bg};
    display: flex;
    min-height: 100%;
    flex-direction: column;
    min-width: 320px;
  }
  // END INITIAL STYLES

  //* { background-color: rgba(255,0,0,.2); }
  //* * { background-color: rgba(0,255,0,.2); }
  //* * * { background-color: rgba(0,0,255,.2); }
  //* * * * { background-color: rgba(255,0,255,.2); }
  //* * * * * { background-color: rgba(0,255,255,.2); }
  //* * * * * * { background-color: rgba(255,255,0,.2); }
  //* * * * * * * { background-color: rgba(255,0,0,.2); }
  //* * * * * * * * { background-color: rgba(0,255,0,.2); }
  //* * * * * * * * * { background-color: rgba(0,0,255,.2); }
`;

export default GlobalStyle;
