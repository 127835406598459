import React from "react";
import styled from "styled-components";
import { breakpoint } from './theme';
import Score from './Score';

const AppThumbStyled = styled.div`
  flex: ${({ flexBasis }) => flexBasis ? flexBasis + '% 0 0' : '33.333% 0 0' };
  padding: 2.5px;
  position: relative;

  ${breakpoint.tabletUp} {
    flex-basis: 20%;
    padding: 6px;
  }

  ${breakpoint.desktopUp} {
    flex-basis: 16.66%;
    padding: 12px;
  }
`;

const ScoreStyled = styled(Score)`
  ${breakpoint.tabletUp} {
    right: 3px;
    bottom: 3px;
  }

  ${breakpoint.desktopUp} {
    right: 9px;
    bottom: 9px;
  }
`;

const AppThumb = ({ score, flexBasis, className, children }) => {
  return (
    <AppThumbStyled className={className} flexBasis={flexBasis}>
      {children}
      {score && <ScoreStyled val={score}/>}
    </AppThumbStyled>
  )
};

export default AppThumb;
