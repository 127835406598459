import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Loader from './Loader';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #171f464c;
  z-index: 4;
  opacity: ${({ active }) => active ? '1' : '0'};
  transition: opacity 0.15s linear;
`;

const PageLoader = () => {
  const [ active, setActive ] = useState(false);

  useEffect(() => {
    setActive(true);
  }, [])

  return (
    <Wrapper active={active}>
      <Loader />
    </Wrapper>
  );
};

export default PageLoader;
