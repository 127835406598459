import React, { useState, useRef } from "react";
import styled from "styled-components";
import { breakpoint } from "./theme";

import UseIcon from './UseIcon';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Inner = styled.div`
  overflow: hidden;
  position: relative;

  ${breakpoint.mobileOnly} {
    position: absolute;
    left: 85px;
    width: calc(100% - 180px);
    opacity: ${({ showForm }) => showForm ? '1' : '0'};
    visibility: ${({ showForm }) => showForm ? 'visible' : 'hidden'};
    transition: ${({ showForm }) => showForm ? 'opacity 0.1s linear 0s, visibility 0s linear 0s' : 'opacity 0.1s linear 0s, visibility 0s linear 0.1s'};
  }
`;

const Input  = styled.input`
  border: 1px solid #D7DFE9;
  height: 40px;
  width: 416px;
  border-radius: 3px;
  padding: 5px 45px;
  background: white;
  -webkit-appearance:none;
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }

  &:focus {
    outline: transparent;
    border-color: #c5cdd6
  }

  ${breakpoint.mobileOnly} {
    padding: 10px;
    width: 100%;
    height: 32px;
  }
`;

const BtnSubmit = styled.button`
  width: 40px;
  height: 40px;
  background: transparent;
  margin: 0;
  border: 0;
  justify-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  padding: 2px 6px 0;

  ${breakpoint.mobileOnly} {
    display: none;
  }
`;

const BtnOpen = styled.button`
  position: relative;
  display: inline-flex;
  width: 43px;
  height: 43px;
  padding: 6px;
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  justify-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  /* background: rgba(126,133,142,0.04); */

  ${breakpoint.tabletUp} {
    display: none;
  }
`;

const IconOpen = styled(UseIcon)`
  fill: #7E858E;
`;

const IconSubmit = styled(UseIcon)`
  fill: #171F46;
  width: 12px;
  height: 12px;
`;

const SearchForm = () => {
  const [value, setValue] = useState('');
  const [showForm, setShowForm] = useState(false);
  const inputRef = useRef(0);

  const onChangeValue = e => {
    setValue(e.target.value);
  };

  const handlerSubmit = e => {
    e.preventDefault();
    if(value) {
      document.dispatchEvent(new CustomEvent('changeHref', {
        detail: { href: '/search/' + value }
      }));

      inputRef.current.blur();
    }
  };

  const handleOpen = e => {
    if (!showForm) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 150);
    } else {
      setTimeout(() => {
        inputRef.current.blur();
      }, 150);
    };

    setShowForm(!showForm);
  };

  return (
    <Container>
      <Inner showForm={showForm}>
        <form action="/search" onSubmit={handlerSubmit}>
          <Input ref={inputRef} value={value} onChange={onChangeValue} name="q" type="search" placeholder="Search by names, categories" />
          <BtnSubmit type="submit"><IconSubmit name="IconSearch" /></BtnSubmit>
        </form>
      </Inner>
      <BtnOpen onClick={handleOpen}><IconOpen name="IconSearch" /></BtnOpen>
    </Container>
  )
};

export default SearchForm;
