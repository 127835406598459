import React, { useState } from "react";
import styled from 'styled-components';
import theme from "./theme";
import MenuButton from './MenuButton';
import UseIcon from "./UseIcon";

const MenuSubButton = styled(MenuButton)`
  font-size: 12px;
  padding: 5px 0 6px;
  align-items: baseline;
  text-align: left;
`;

const ButtonOpen = styled.button`
  position: absolute;
  right: 5px;
  bottom: 2px;
  width: 26px;
  height: 26px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  transform: ${props => props.opened ? 'rotate(180deg) translate(0, -2px)' : 'rotate(0)'};
  transition: transform 0.2s ease-in-out;
`;

const Subcat = styled.div`
  overflow: hidden;
  max-height: ${props => props.opened ? '2000px' : '0'};
  transition: ${props => props.opened ? 'max-height 1s linear' : 'max-height 0.5s cubic-bezier(0, 1, 0.5, 1)'};
`;

const SubIcon = styled.span`
  min-width: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
`;

const MenuSubcat = (props) => {
  const { subitems } = props;
  const [ opened, setOpened ] = useState(false);

  const handleOpen = () => {
    setOpened(!opened);
  }

  return (
    <>
      <ButtonOpen onClick={handleOpen} opened={opened}>
        <UseIcon name="IconChevron" color={opened ? theme.color.text : theme.color.bright} />
      </ButtonOpen>
      <Subcat opened={opened}>
        {subitems.map((item, key) => {
          return <MenuSubButton key={key} href={item.path}><SubIcon><UseIcon name="IconSubcat" /></SubIcon>{item.title}</MenuSubButton>
        })}
      </Subcat>
    </>
  )
};

export default MenuSubcat;
