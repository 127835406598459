import React from "react";
import styled from "styled-components";

import { breakpoint } from './theme';

const ScoreTag = styled.div`
  width: ${({ size }) => size ? size : 26}px;
  height: ${({ size }) => size ? size : 26}px;
  font-size: 12px;
  position: absolute;
  right: 0;
  bottom: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 500;
  background-color: white;

  ${breakpoint.tabletUp} {
    width: ${({ size }) => size ? size : 40}px;
    height: ${({ size }) => size ? size : 40}px;
    font-size: 16px;
  }
`;

const Score = ({ val, size, className }) => (
  <ScoreTag size={size} className={className}>{val}</ScoreTag>
);

export default Score;
