import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import theme, { breakpoint } from './theme';

const Container = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: ${({ showMore }) => showMore ? "32px" : "0"};
  transition: ${({ showMore, folded }) => {
    if (!showMore) return false;
    else if (folded) return 'max-height 0.25s cubic-bezier(1, 0, 1, 1)';
    else if (!folded) return 'max-height 0.25s cubic-bezier(0, 0, 0, 1)';
  }};
  max-height: ${({ showMore, folded }) => showMore && !folded ? "100px" : "2000px"};

  &::before {
    content: '';
    display: ${({ showMore, folded }) => showMore && !folded ? 'block' : 'none'};
    position: absolute;
    bottom: 33px;
    left: 0;
    width: 100%;
    height: 15px;
    background: linear-gradient(#ffffff29 0% ,#ffffff 90%);

    ${breakpoint.tabletUp} {
      background: linear-gradient(#FBFBFB29 0% ,#FBFBFB 90%);
    }
  }

  ${breakpoint.tabletUp} {
    flex: 53% 0 0;
    max-height: ${({ showMore, folded }) => showMore && !folded ? "114px" : "2000px"};
  }

  ${breakpoint.desktopUp} {
    flex: 61% 0 0;
  }
`;

const Fold = styled.div`
  line-height: 1.715em;
  font-size: 14px;
  letter-spacing: -0.014em;
  margin-bottom: 5px;
  white-space: pre-line;

  ${breakpoint.tabletUp} {
    font-size: 16px;
    line-height: 1.5em;
  }
`;

const More = styled.button`
  font-family: inherit;
  margin: 0;
  padding: 8px 9px 9px;
  border: 0;
  border-top: 1px solid ${({ folded }) => folded ?  'rgb(233,	237,	242)' : 'transparent'};
  border-bottom: 1px solid rgb(233,	237,	242);
  background: white;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  color: inherit;
  user-select: none;
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  ${breakpoint.tabletUp} {
    background: #FBFBFB;
    border: 0;
    text-align: left;
    padding-left: 0;
    font-size: 14px;
  }
`;

const FoldedBlock = props => {
  const { visible } = props
  const foldRef = useRef(null);
  const [showMore, setShowMore] = useState(false);
  const { folded, setFolded } = props;

  const moreHandler = () => {
    const height = foldRef.current.clientHeight;

    if(height >= 100 || height === 0) {
      setShowMore(true);
    } else {
      setShowMore(false);
    }
  }

  useEffect(() => {
    moreHandler();
  }, [visible]);

  useEffect(() => {
    window.addEventListener('resize', moreHandler);

    return () => {
      window.removeEventListener('resize', moreHandler);
    }
  }, []);

  return (
    <Container showMore={showMore} folded={folded}>
      <Fold ref={foldRef}>{props.children}</Fold>
      {showMore && <More onClick={() => setFolded(!folded)} folded={folded}>Show {folded ? 'less' : 'more'}</More>}
    </Container>
  )
};

export default FoldedBlock;
