import React, { useState } from "react";
import styled from "styled-components";
import theme, { breakpoint } from './theme';

import UseIcon from "./UseIcon";
import Loader from './Loader';

const Button = styled.a`
  display: inline-flex;
  border: 0;
  margin: 0;
  cursor: pointer;
  background: linear-gradient(92.86deg, ${theme.color.btnL} 0%, ${theme.color.btnD} 44.89%, ${theme.color.btnD} 54.61%, ${theme.color.btnL} 100%);;
  color: white;
  height: 40px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0px 4px 16px rgba(126, 133, 142, 0.16);
  width: 100%;
  position: relative;

  ${breakpoint.tabletUp} {
    height: 48px;
  }
`;

const UseIconSt = styled(UseIcon)`
  margin-bottom: 2px;
  margin-right: 9px;
`;

const Text = styled.span`
  padding-right: 34px;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  line-height: 0.85em;
  letter-spacing: 0.03em;
`;

const Right = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: white;
  height: 38px;
  width: 39px;
  position: absolute;
  right: 1px;
  top: 1px;
  border-radius: 0 3px 3px 0;
  color: ${theme.color.bright};
  font-size: 19px;

  ${breakpoint.tabletUp} {
    height: 46px;
    width: 47px;
    font-size: 21px;
  }
`;

const RightIcon = styled.span`
  display: ${props => props.showLoader ? 'none' : 'block'};
  &::before {
    content: '→';
  }
`;

const RightLoader = styled(Loader)`
  display: ${props => props.showLoader ? null : 'none'};
  vertical-align: middle;
  position: relative;
  margin: 0;
  height: 22px;
  width: 22px;
  border-width: 2px;
`;

const BtnDlGame = ({ className, url, onClick, timeout }) => {
  const [showLoader, setShowLoader ] = useState(false);

  const handleClick = () => {
    onClick();
    if (timeout) setShowLoader(true);
  };

  return (
    <Button className={className} href={url} onClick={handleClick} data-timeout={timeout}>
      <UseIconSt color="white" name="IconDownload" />
      <Text>Download</Text>
      <Right><RightIcon showLoader={showLoader}/><RightLoader showLoader={showLoader}/></Right>
    </Button>
  )
};

export default BtnDlGame;
