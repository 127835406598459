const getImage = (src, size, multiplier) => {
  let res;
  const pathPattern = /^\/[a-z]\//;
  const namePattern = /[^\/]+$/g;

  const mSize = (typeof size === 'number') ? size + 'x' + size : size;
  const imgAr = src.split('.');
  const imagePath = imgAr[0].match(pathPattern)[0];
  const imageName = imgAr[0].match(namePattern)[0];
  const imageExt = imgAr[1];

  res = imagePath + mSize + '/' + imageName + '-' + (multiplier || 1) + 'x.' + imageExt;

  return res;
};

export default getImage;
