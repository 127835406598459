import React, { useEffect } from "react";
import ajaxGet from "../utils/ajaxGet";
import styled from 'styled-components';
import { useStoreon } from 'storeon/react';
import { breakpoint } from './theme';
import setMeta from '../utils/setMeta';

import PageLoader from './PageLoader';
import CatHead from './CatHead';
// import Adv from './Adv';
import Thumb from "./Thumb";
import AppThumb from './AppThumb';

const List = styled.div`
  display: flex;
  margin: 0 auto 5px;
  padding: 0 7.5px;
  max-width: 1024px;
`;

const AppThumbStyled = styled(AppThumb)`
  &:nth-child(5),
  &:nth-child(6) {
    display: none;
  }

  ${breakpoint.tabletUp} {
    &:nth-child(5) {
      display: block;
    }
  }

  ${breakpoint.desktopUp} {

    &:nth-child(6) {
      display: block;
    }
  }
`;

const PageHome = () => {
  const { dispatch, homePage } = useStoreon('homePage');

  useEffect(() => {
    if (!homePage) {
      ajaxGet(`/index.json`)
        .then(data => {
          console.log(data.homePage);
          dispatch('homePage/success', data.homePage);
          setMeta(data.homePage.pageTitle, data.homePage.pageDescription, data.homePage.keywords);
        })
        .catch(error => {
          dispatch('homePage/fail');
        });
    } else {
      setMeta(homePage.pageTitle, homePage.pageDescription, homePage.keywords);
    }
  }, []);

  return (
    <>
      {homePage && homePage.categories.map((category, key) => {
        return (
          <React.Fragment key={key}>
            {/* {key === 1 && <Adv />} */}
            <CatHead {...category} />
            <List>
              {category.items.slice(0,6).map(item => (
                <AppThumbStyled flexBasis="25" key={item.id} {...item} >
                  <Thumb
                    href={item.url}
                    image={item.image}
                    title={item.title}
                    sizeMobileSmall={111}
                    sizeMobileBig={173}
                    sizeTablet={189}
                  />
                </AppThumbStyled>
              ))}
            </List>
          </React.Fragment>
        )
      })}
      {!homePage && <PageLoader />}
    </>
  )
}

export default PageHome;
