import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import sendAnalyticsEvent from "../utils/sendAnalyticsEvent";

import UseIcon from "./UseIcon";
import SliderItem from "./SliderItem";

const Container = styled.div`
  overflow: hidden;
  margin: 0 -4px;
`;

const List = styled.div`
  display: flex;
  width: max-content;
  will-change: transform;
  transition: transform 0.2s linear;
`;

const UseIconSt = styled(UseIcon)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

const Slider = ({ slug, video, pictures, handleVisible }) => {
  const containerRef = useRef(null);
  const listRef = useRef(null);
  const firstMove = useRef(null);
  const [slidesMap, setSlidesMap] = useState([]);
  const [curPosition, setCurPosition] = useState(0);
  const [active, setActive] = useState(0);
  const showArr = useRef(3);
  const loaded = useRef(0);

  let moving = null;
  let startPosCur = null;
  let changed = null;

  const makeMap = () => {
    const list =  listRef.current.children;
    let mapLeft = [];

    for (let item of list) {
      mapLeft.push(item.offsetLeft);
    }

    setSlidesMap(mapLeft);
  };

  const setPosition = pos => {
    if (listRef.current) listRef.current.style.transform = `translate3d(-${pos}px, 0px, 0px)`;
  };

  const getPosition = (goal) => {
    let closestItem = (curPosition <= 0) ? getClosestItem(-goal) : getClosestItem(-goal + curPosition);
    
    // активный элемент, относительно которого необходимо загружать элементы
    setActive(slidesMap.indexOf(closestItem));

    return closestItem;
  };

  const getClosestItem = (goal) => {
    return slidesMap.reduce((prev, curr) => Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
  };

  useEffect(() => {
    setCurPosition(0);
    // makeMap();
    

    return () => {
      firstMove.current = false;
      showArr.current = 3;
      loaded.current = 0;
      slidesMap.current = [];
    }
  }, [slug]);

  const handleImgLoad = (offsetRight) => {
    loaded.current++;

    if ((loaded.current === showArr.current) && (offsetRight < containerRef.current.offsetWidth * 2)) {
      showArr.current++;
    }

    makeMap();
  }

  // useEffect(() => {
    // console.log('active', active);
    // console.dir(listRef.current.children[active].firstChild);
  // }, [active]);

  useEffect(() => {
    if (listRef.current) setPosition(curPosition);
  }, [curPosition]);

  const pickup = event => {
    moving = listRef.current;
    startPosCur = event.changedTouches[0].clientX;
    moving.style.transition = 'none';
    setPosition(curPosition);
  };

  const move = event => {
    if (moving) {
      const movePosCur = event.changedTouches[0].clientX;
      changed = movePosCur - startPosCur;
      moving.style.transform = `translate3d(${changed -curPosition}px, 0px, 0px)`;

      if (!firstMove.current) {
        firstMove.current = true;
        sendAnalyticsEvent('SCREENS_SCROLL', { id: slug });
      }
    }
  };

  const drop = () => {
    if (moving) {
      moving.style.transition = '';

      if (Math.abs(changed) >= 50) {
        const position = getPosition(changed);

        if ((position + containerRef.current.offsetWidth) > listRef.current.offsetWidth) {
          const position = listRef.current.offsetWidth - containerRef.current.offsetWidth;
          setCurPosition(position);
          setPosition(position);
        } else {
          setCurPosition(position);
          setPosition(position);
        }

      } else {
        setPosition(curPosition);
      }

      startPosCur = null;
      changed = null;
    }
  };

  return (
    <>
      {((video && video.poster) || (pictures && pictures.length > 0)) && <Container ref={containerRef}>
        <List
          ref={listRef}
          onTouchStart={pickup}
          onTouchEnd={drop}
          onTouchMove={move}
        >
          {/* {video && video.poster && <Slide>
            <UseIconSt name="IconPlay" color="white" />
            <Thumb src={video.poster} onLoad={handleImgLoad} />
          </Slide>} */}
          {pictures.map((item, key) => (<SliderItem
              key={slug + key}
              pos={key}
              item={item}
              show={key < showArr.current}
              showAll={firstMove.current}
              onClick={() => handleVisible(key)}
              onLoad={handleImgLoad}
            />))}
        </List>
      </Container>}
    </>
  )
};

export default Slider;
