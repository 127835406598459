import React, { useEffect, useRef, useState } from 'react';
import ajaxGet from '../utils/ajaxGet';
import { useStoreon } from "storeon/react";
import styled from 'styled-components';
import Loader from './Loader';
import PageLoader from './PageLoader';
import { breakpoint } from './theme';
import debounce from '../utils/debounce';
import setMeta from '../utils/setMeta';
import getJsonPath from '../utils/getJsonPath';
import checkHeight from '../utils/checkHeight';
import handleScroll from '../utils/handleScroll';
import theme from './theme';

import Adv from './Adv';
import CatHead from './CatHead';
import Info from './Info';
import Thumb from './Thumb';

import sendAnalyticsEvent from '../utils/sendAnalyticsEvent';

const Collection = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`;

const List = styled.div`
  margin: 9px 10px 5px;

  ${breakpoint.tabletUp} {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 5px 5px;
  }

  ${breakpoint.desktopUp} {
    margin: 10px 0 5px;
  }
`;

const LastList = styled(List)`
  margin-bottom: 45px;
`;

const Item = styled.div`
  min-width: 0;
  margin: 4px 0;

  ${breakpoint.tabletUp} {
    flex: 0 0 50%;
    margin: 3px 0;
    padding: 0 3px;
  }

  ${breakpoint.desktopUp} {
    flex-basis: 33.333%;
  }
`;

const Link = styled.a`
  border: 1px solid #D7DFE9;
  padding: 5px 9px;
  color: ${theme.color.text};
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 4px;
  background: white;
  position: relative;
`;

const Picture = styled.div`
  flex: 56px 0 0;
  position: relative;
`;

const Cnt = styled.div`
  padding-left: 10px;
  padding-bottom: 1px;
  overflow: hidden;
`;

const Name = styled.div`
  font-size: 16px;
  margin-bottom: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Pos = styled.div`
  font-size: 12px;
  font-weight: 500;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0px;
`;

const ThumbStyled = styled(Thumb)`
  border-radius: 4px;

  ${breakpoint.tabletUp} {
    border-radius: 8px;
  }
`;


const ItemFirst = styled(Item)`
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6) {
    display: none;
  }

  ${breakpoint.tabletUp} {
    &:nth-child(3),
    &:nth-child(4) {
      display: block;
    }
  }

  ${breakpoint.desktopUp} {
    &:nth-child(5),
    &:nth-child(6) {
      display: block;
    }
  }
`;

const ItemSecond = styled(Item)`
  ${breakpoint.tabletUp} {
    &:nth-child(1),
    &:nth-child(2) {
      display: none;
    }
  }

  ${breakpoint.desktopUp} {
    &:nth-child(3),
    &:nth-child(4) {
      display: none;
    }
  }
`;

const PlaylistLoader = styled(Loader)`
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  width: 35px;
  height: 35px;
  margin-top: -40px;
  margin-bottom: 5px;
`;

const PageCollection = ({ slug }) => {
  const { dispatch, collectionPage } = useStoreon('collectionPage');
  const { ads } = useStoreon('ads');

  const count = useRef(collectionPage ? collectionPage.items.length : 0);
  const isFetching = useRef(false);
  const [showLoader, setShowLoader] = useState(false);
  const scroller = useRef(null);
  const currentSlug = useRef(collectionPage? collectionPage.slug : '');
  const limit = useRef(12)

  useEffect(() => {
    window.addEventListener('scroll', debouncedScrollListener);
    return () => {
      window.removeEventListener('scroll', debouncedScrollListener);
    }
  }, []);

  useEffect(() => {
    if (!slug || (collectionPage && collectionPage.slug === slug)) {
      setMeta(collectionPage.pageTitle, collectionPage.pageDescription, collectionPage.keywords);
      checkHeight(loadingElements);
    } else {
      ajaxGet(getJsonPath())
        .then(data => {
          count.current = data.collectionPage.items.length;
          dispatch('collectionPage/success', data.collectionPage);
          setMeta(data.collectionPage.pageTitle, data.collectionPage.pageDescription, data.collectionPage.keywords);
          currentSlug.current = data.collectionPage.slug;
          setTimeout(() => checkHeight(loadingElements), 0);
        })
        .catch(error => {
          dispatch('collectionPage/fail');
        });
    }
  }, [slug]);

  const loadingElements = () => {
    isFetching.current = true;
    setShowLoader(true);

    ajaxGet(`${'/data/thumbs/collections/' + currentSlug.current}?offset=${count.current}&limit=${limit.current}`)
      .then(data => {
        if (data.items.length === limit.current) isFetching.current = false;
        setShowLoader(false);
        count.current = count.current + data.items.length;
        dispatch('collectionPage/add', data.items);
        setTimeout(() => {
          if (!isFetching.current) checkHeight(loadingElements)
        }, 100);
      })
      .catch(error => {
        dispatch('collectionPage/fail');
      })
  };

  const hs = () => handleScroll(scroller.current, isFetching.current, loadingElements);
  const debouncedScrollListener = debounce(hs, 150);

  const handleClick = itemId => {
    sendAnalyticsEvent('APP_CLICK', { id: itemId });
  }

  return (
    <>
      {collectionPage && <Collection ref={scroller}>
      <CatHead name={collectionPage.title} icon={collectionPage.icon} />
      <List>
        {collectionPage.items.slice(0,6).map((item, key) => {
          return (
            <ItemFirst key={item.id}>
              <Link href={item.url} onClick={() => handleClick(item.id)}>
                <Picture>
                  <ThumbStyled
                    image={item.image}
                    sizeAll={56}
                  />
                </Picture>
                <Pos>{key+1}</Pos>
                <Cnt>
                  <Name>{item.title}</Name>
                  <Info loaded={item.loaded} price={item.price} score={item.score} size={item.size} />
                </Cnt>
              </Link>
            </ItemFirst>
          )
        })}
      </List>

      {ads.slots['zisgames_list_ATF'] && <Adv slot={ads.slots['zisgames_list_ATF']} />}

      {collectionPage.items.length > 2 && <LastList>
        {collectionPage.items.slice(2).map((item, key) => {
          return (
            <ItemSecond key={item.id}>
              <Link href={item.url} onClick={() => handleClick(item.id)}>
                <Picture>
                  <ThumbStyled
                    image={item.image}
                    sizeAll={56}
                  />
                </Picture>
                <Pos>{key+3}</Pos>
                <Cnt>
                  <Name>{item.title}</Name>
                  <Info loaded={item.loaded} price={item.price} score={item.score} size={item.size} />
                </Cnt>
              </Link>
            </ItemSecond>
          )
        })}
      </LastList>}

      {showLoader && <PlaylistLoader />}
    </Collection>}

    {!collectionPage && <PageLoader />}
    </>
  )
}

export default PageCollection;
