import React from 'react';
import { useStoreon } from 'storeon/react';

import PagePrelandZ1 from './PagePrelandZ1';
import PagePrelandZ2 from './PagePrelandZ2';

const PagePreland = () => {
  const { landerPage } = useStoreon('landerPage');
  const { landingTemplate } = landerPage;
  const PrelandTemplate = {
    z1: PagePrelandZ1,
    z2: PagePrelandZ2,
  };
  const PrelandComponent = PrelandTemplate[landingTemplate];

  return (
    <PrelandComponent />
  )
};

export default PagePreland;
