import { createStoreon } from 'storeon';

import menu from './menu.js';
import router from './router';
import homePage from './homePage.js';
import collectionPage from './collectionPage.js';
import categoryPage from './categoryPage.js';
import appPage from './appPage.js';
import landerPage from './landerPage.js';
import articlePage from './articlePage.js';
import contactPage from './contactPage.js';
import ads from './ads.js';

const store = (pathName, home, collection, category, app, lander, article, contact, menuStore, adsSlots, cookiePolicy, legalLinks, siteFeatures) => (
  createStoreon([
    router(pathName),
    homePage(home),
    collectionPage(collection),
    categoryPage(category),
    appPage(app),
    landerPage(lander),
    articlePage(article),
    contactPage(contact),
    menu(menuStore),
    ads(adsSlots),
    store => store.on('@init', () => ({ cookiePolicy, legalLinks, siteFeatures })),
  ])
);

export default store;
