import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import Loader from './Loader';

const Container = styled.div`
  width: 100%;
  flex: 100% 0 0;
  display: inline-flex;
  max-height: 100%;
  user-select: none;
  position: relative;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  opacity: ${({ loaded }) => loaded ? '1' : '0'};
  transition: opacity 0.2s linear;
  object-fit: contain;
`;

const ViewerImage = ({ src, srcSet, visible, show }) => {
  const imageRef = useRef(null);
  const [ loaded, setLoaded ] = useState(false);

  useEffect(() => {
    if (imageRef.current && imageRef.current.complete && imageRef.current.src) {
      handleLoad();
    }
  }, []);

  const handleLoad = () => {
    setLoaded(true);
  };

  const handleError = () => {
    setLoaded(false);
  };

  return (
    <Container>
      <Image
        ref={imageRef}
        src={show ? src : null}
        srcSet={show ? srcSet : null}
        loaded={loaded}
        onLoad={handleLoad}
        onError={handleError}
      />

      {!loaded && visible && <Loader />}
    </Container>
  )
};

export default ViewerImage;
