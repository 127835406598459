import React from "react";
import styled from 'styled-components';

import { breakpoint } from './theme';
import LogoHeader from './LogoHeader.jsx';
import Hamburger from './Hamburger';
import CookieBanner from "./CookieBanner";
import SearchForm from "./SearchForm";
import { useStoreon } from "storeon/react";

const HeaderTag = styled.header`
  background: white;
  z-index: 3;
  position: relative;
`;

const HeaderInner = styled.div`
  max-width: 1024px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 10px;
  margin: 0 auto;
  width: 100%;
  height: 43px;

  ${breakpoint.tabletUp} {
    height: 61px;
  }
`;

const Right = styled.div`
  display: flex;

  ${breakpoint.tabletUp} {
    justify-content: space-between;
    width: 100%;
    flex-basis: 100%;
    padding-left: 74px;
  }
`;

const Header = () => {
  const { cookiePolicy } = useStoreon('cookiePolicy');

  return (
    <HeaderTag>
      <HeaderInner>
        <LogoHeader />
        <Right>
          <SearchForm />
          <Hamburger />
        </Right>
      </HeaderInner>
      {cookiePolicy.show && <CookieBanner {...cookiePolicy} />}
    </HeaderTag>
  )
}

export default Header;
