import React from "react";
import styled, { css } from 'styled-components';
import { useStoreon } from 'storeon/react';
import { breakpoint } from './theme';
import sendAnalyticsEvent from "../utils/sendAnalyticsEvent";

const HamburgerBox = styled.button`
  position: relative;
  display: inline-flex;
  width: 43px;
  height: 43px;
  padding: 6px;
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  justify-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border-left: ${props => props.active ? '1px solid rgba(215, 223, 233, 0.5)' : '1px solid transparent' } ;
  border-right: ${props => props.active ? '1px solid rgba(215, 223, 233, 0.5)' : '1px solid transparent' } ;
  background: ${props => props.active ? 'rgba(126, 133, 142, 0.04)' : false } ;

  ${breakpoint.tabletUp} {
    width: 61px;
    height: 61px;
  }
`;

const HamburgerLine = css`
  position: absolute;
  width: 21px;
  height: 3px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: #7E858E;

  ${breakpoint.tabletUp} {
    height: 4px;
    width: 30px;
  }
`;

const HamburgerInner = styled.div`
  top: 50%;
  display: block;
  margin-top: -2px;
  ${HamburgerLine};
  
  transition-duration: 75ms;
  transition-delay: ${props => props.active ? '.12s' : false} ;
  transform: ${props => props.active ? 'rotate(45deg)' : false};
  transition-timing-function: ${props => props.active ? 'cubic-bezier(.215,.61,.355,1)' : 'cubic-bezier(.55,.055,.675,.19)'};

  ::before, ::after {
    ${HamburgerLine};
    display: block;
    content: "";
  }

  ::before {
    top: ${props => props.active ? '0' : '-7px'};
    transition: ${props => props.active ? 'top 75ms ease,opacity 75ms ease .12s;' : 'top 75ms ease .12s,opacity 75ms ease'};
    opacity: ${props => props.active ? '0' : '1'};

    ${breakpoint.tabletUp} {
      top: ${props => props.active ? '0' : '-9px'};
    }
  }

  ::after {
    bottom: ${props => props.active ? '0' : '-7px'};
    transition: ${props => props.active ? 'bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;' : 'bottom 75ms ease .12s,transform 75ms cubic-bezier(.55,.055,.675,.19)'};
    transform: ${props => props.active ? 'rotate(-90deg)' : false};
    
    ${breakpoint.tabletUp} {
      bottom: ${props => props.active ? '0' : '-9px'};
    }
  }
`;

const Hamburger = () => {
  const { dispatch, menu } = useStoreon('menu');
  const clickHandler = () => {
    if (!menu.open)
      sendAnalyticsEvent('MENU_SHOW');
    dispatch('menu/change')
  };

  return (
    <>
      <HamburgerBox active={menu.open} onClick={clickHandler} className="hamburger" aria-label="menu-button">
        <HamburgerInner active={menu.open} />
      </HamburgerBox>
    </>
  )
};

export default Hamburger;
