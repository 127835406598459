import React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from './theme';

const Rotate = keyframes`
  0% {
      transform: rotate(0)
    }
  to {
    transform: rotate(1turn)
  }
`;

const Spinner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid ${({ color }) => color ? color : theme.color.bright};
  border-left-color: transparent;
  animation: ${Rotate} 1s linear infinite;
`;

const Loader = ({ className, color }) => (
  <Spinner className={className} color={color} />
);

export default Loader;
