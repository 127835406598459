import React from "react";

const sizes = {
  IconChevron: { width: 13, height: 8 },
  IconStar: { width: 10, height: 10 },
  IconDl: { width: 12, height: 12 },
  IconClose: { width: 16, height: 16 },
  IconNext: { width: 30, height: 48 },
  IconPrev: { width: 30, height: 48 },
  IconDownload: { width: 18, height: 22 },
  IconPlay: { width: 56, height: 56 },
  IconCheckbox: { width: 16, height: 16 },
  IconCheckboxActive: { width: 16, height: 16 },
  IconSearch: { width: 19, height: 19 },

  // Categories
  IconAbc: { width: 21, height: 20 },
  IconArcade: { width: 22, height: 21 },
  IconBall: { width: 20, height: 20 },
  IconBest: { width: 21, height: 21 },
  IconCar: { width: 22, height: 15 },
  IconCards: { width: 21, height: 20 },
  IconCategorize: { width: 18, height: 18 },
  IconChess: { width: 20, height: 21 },
  IconFast: { width: 22, height: 22 },
  IconFinish: { width: 20, height: 21 },
  IconGun: { width: 23, height: 17 },
  IconHeart: { width: 20, height: 19 },
  IconJet: { width: 23, height: 20 },
  IconLeaf: { width: 18, height: 21 },
  IconMario: { width: 20, height: 20 },
  IconMusical: { width: 18, height: 19 },
  IconPortal: { width: 21, height: 21 },
  IconPrize: { width: 20, height: 24 },
  IconSaber: { width: 21, height: 24 },
  IconStrategy: { width: 21, height: 21 },
  IconStudy: { width: 20, height: 17 },
  IconThumbsUp: { width: 20, height: 18 },
  IconHunt: { width: 24, height: 24 },
  IconSubcat: { width: 9, height: 8 },
}

const UseIcon = ({ width, height, color = '#FF7E21', name, className }) => {
  const dName = name === "IconDefault" ? 'IconCategorize' : name;
  const nwidth = width ? width : sizes[dName] ? sizes[dName].width : 20;
  const nheight = height ? height : sizes[dName] ? sizes[dName].height : 20;

  return (
    <svg className={className} width={nwidth} height={nheight}  fill={color}>
      <use xlinkHref={`#${dName}`} />
    </svg>
  )
};

export default UseIcon;
